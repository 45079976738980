import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="Números" />
      <h1>Tipos y Sombras En El Antiguo Testamento </h1>
      <p>Abajo están las clases transcritas.</p>
      <h2>Tipos y Sombras en Números</h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>
              <div align="center">01</div>
            </td>
            <td>Números 3 - El Primogénito</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/082TS_Numeros_3.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">02</div>
            </td>
            <td>Números 5 - La Ley de Los Celos</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/083TS_Numeros_5.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">03</div>
            </td>
            <td>Números 7, 9 - El Arca y La Nube</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/084TS_Numeros_7-9.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">04</div>
            </td>
            <td>Números 10 - Las Trompetas, Seguir el Arca</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/085TS_Numeros_10.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">05</div>
            </td>
            <td>Números 11 - La Disciplina del Señor</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/086TS_Numeros_11.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td width="29">
              <div align="center">06</div>
            </td>
            <td width="434">Números 14 - Rechazar el Propósito de Dios</td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/087TS_Numeros_14.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
